// Network utilities
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { client as WebSocketClient } from "websocket";
import { processServerMessage } from "../App";
import Keycloak from 'keycloak-js';
import { v4 as uuidv4 } from 'uuid';
import postal from 'postal';
import { 
  setUser, 
  setAuthenticated, setKeycloakInitialized, setAuthToken, setOidcEnabled, setFormEnabled, setAuthenticatedAsync,
  setWebsocketInitialized, setWebsocketOpened, setWebsocketErrorMessage, auth
} from '../features/network/networkSlice';
import store from '../app/store';


const wsproto = window.location.protocol === 'http:' ? 'ws://' : 'wss://';
const serverPath = window.location.hostname + ':' + window.location.port + '/api/socket';
const serverUrl = wsproto + serverPath;
console.log("Using server: " + serverUrl);


const UserInfo = {
  info: { name: 'anonymous', email: 'anonymous@nodomain.com', givenName: 'Anonymous', familyName: '', roles: [], sessionId: uuidv4() }
};

var oidcEnabled;
var keycloak = { token: 'DUMMY', logout: (options) => {} };
var UserProfile = {};


var pollServerMessages = true;
var webSocketOpened = false;
var webSocketError = false; 

const getAuthHeaders = () => {
  // Don't send Bearer token until it's ready.
  // The server will use its own OIDC authorization flow if
  // Authorization header is absent, but if present it has to be valid
  let auth = {};
  if ( keycloak.token !== 'DUMMY' ) {
    auth['Authorization'] = 'Bearer ' + keycloak.token;
  }
  return auth;
}

const netGet = (url) => {
  let auth = getAuthHeaders();
  const myheaders = { ...auth};
  // console.log('netGet headers: ' + JSON.stringify(myheaders));
  return fetch(url, {
    headers: myheaders,
  });
}

const netPost = (url, postData) => {
  let auth = getAuthHeaders();
  const myheaders = { 'Content-Type': 'application/json', ...auth};
  // console.log('netPost headers: ' + JSON.stringify(myheaders));
  return fetch(url, {
      method: 'POST',
      headers: myheaders,
      body: JSON.stringify(postData),
  });
};

const netFetch = (url, options) => {
  /*
  if ( options && options.headers ) {
    console.log('netFetch headers: ' + JSON.stringify(options.headers));
  }
  */
  return fetch(url, options);
}

const viewedNotifications = new Set();
const notificationIcon = '/images/application.svg';

const processNotifications = () => {
  netGet('/api/notify/user')
    .then(response => response.json())
    .then(data => {
      if (Array.isArray(data)) {
        if ( data.length > 0 && !(Notification?.permission === 'granted') && !(Notification?.permission === 'denied') ) {
          Notification.requestPermission().then((result) => {
            console.log('Request notification permission = ' + result);
          });
        }
        let viewed = [];
        data.forEach(notice => {
          if (notice.id && !viewedNotifications.has(notice.id)) {
            console.log('New User Notification: ' + JSON.stringify(notice));
            viewedNotifications.add(notice.id);
            notice.userState = 'READ';
            viewed.push(notice);
            if (notice.category === 'WORKFLOW') {

              if (Notification?.permission === 'granted') {
                const title = 'UCS Workflow';
                const n = new Notification(title, { body: notice.message, icon: notificationIcon });
              }
            }
          }
        });
        if (viewed.length > 0) {
          netPost('/api/notify/user', viewed)
            .then(resp => {
              if (!resp.ok) {
                console.log('Could not update notification: status = ' + resp.status);
              }
            }).catch(error => {
              // ignore
            });;
        }
      }
    })
    .catch(error => {
      // ignore
    });
};


const onServerMessage = (message) => {
  if (message.data) {
    try {
      const dataFromServer = JSON.parse(message.data);
      // console.log('Message: ' + dataFromServer);

      // broadcast the message
      postal.publish({
        topic: "websocket.message",
        data: dataFromServer
      });
      // call the main app handling directly
      // TODO: change it to handle the postal message, so everybody uses the same API
      processServerMessage(dataFromServer);
    } catch (error) {
      console.log('Error processing websocket message: ' + error);
    }
  }
};


// not needed anymore, we always poll
const keepPolling = () => {
  try {
    window.clearInterval(pollingIntervalInst);
  } catch (ex) {
    // do nothing
  }
  pollingIntervalInst = window.setInterval(pollServer, 2200);
};

const stringify  = function(value, space) {
  var cache = [];

  var output = JSON.stringify(value, function (key, value) {

      //filters vue.js internal properties
      if(key && key.length>0 && (key.charAt(0)=="$" || key.charAt(0)=="_")) {

          return;
      }

      if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
              // Circular reference found, discard key
              return;
          }
          // Store value in our collection
          cache.push(value);
      }


      return value;
  }, space)

  cache = null; // Enable garbage collection

  return output;
}

const openDefaultWebSocket = (onErrorOrClose) => {
  if ( webSocketOpened ) {
    return;
  }
  // we add the token as a query parameter as there's not way to add an Authorization header
  let wsUrl = serverUrl + '?u=' + UserProfile.username;
  if ( typeof UserInfo.info.sessionId === 'string' && UserInfo.info.sessionId.length > 0) {
    // add old ws sessionId so that server can replace/merge and continue the old session
    wsUrl = wsUrl + '&osid=' + UserInfo.info.sessionId;
  }
  const client = new WebSocketClient();
  let conn;

  client.on('connectFailed', function (error) {
    console.log('Connect Error: ' + error.toString());
    console.log('WebSocket error: ' + stringify(error));
    console.log('Falling back to polling...')

    webSocketError = true;
    store.dispatch(setWebsocketErrorMessage(stringify(error)));
  });

  client.on('connect', function (connection) {
    console.log('WebSocket Client Connected');
    conn = connection;
    pollServerMessages = false;
    webSocketOpened = true;
    webSocketError = false;
    runInitHooks();
    postal.publish({
      topic: "websocket.initialized",
      data: {
        // this is the old sessionId
        sessionId: UserInfo.info.sessionId
      }
    });
    store.dispatch(setWebsocketOpened(true));

    connection.on('error', function (error) {
      console.log("WebSockent Connection Error: " + error.toString());
      console.log('WebSocket error: ' + stringify(error));
      console.log('Falling back to polling...')
    
      webSocketError = true;
      store.dispatch(setWebsocketErrorMessage(stringify(error)));
    });

    connection.on('close', function () {
      console.log('WebSocket Connection Closed');
      // console.log('WebSocket close, was clean: ' + event.wasClean);
      // the socket should be active all the time the app is running!
      // but server could have closed it, maybe session expired!
      pollServerMessages = true;
      webSocketOpened = false;
      store.dispatch(setWebsocketOpened(false));
      //webSocketError = event.wasClean;
      if (typeof onErrorOrClose === 'function') {
        onErrorOrClose();
      }
    });

    connection.on('message', onServerMessage);

    /*
    function sendNumber() {
      if (connection.connected) {
        var number = Math.round(Math.random() * 0xFFFFFF);
        connection.sendUTF(number.toString());
        setTimeout(sendNumber, 1000);
      }
    }
    sendNumber();
    */
  });
  client.connect(wsUrl, 'ucs', null, getAuthHeaders());
  return { client: client, connection: conn};
}

const openWebSocket = (onErrorOrClose) => {
  if ( webSocketOpened ) {
    return;
  }
  // we add the token as a query parameter as there's not way to add an Authorization header
  let wsUrl = serverUrl + '?u=' + UserProfile.username;
  if ( typeof UserInfo.info.sessionId === 'string' && UserInfo.info.sessionId.length > 0) {
    // add old ws sessionId so that server can replace/merge and continue the old session
    wsUrl = wsUrl + '&osid=' + UserInfo.info.sessionId;
  }
  // const client = new W3CWebSocket(wsUrl, 'ucs', null, getAuthHeaders());
  const client = new W3CWebSocket(wsUrl, null, null, getAuthHeaders());
  store.dispatch(setWebsocketInitialized(true));

  client.onopen = () => {
    console.log('WebSocket Client Connected');
    // this stop the polling but switches to sending keepalives to the websocket
    pollServerMessages = false;
    webSocketOpened = true;
    webSocketError = false;
    runInitHooks();
    /*
    // terminate polling
    try {
      window.clearInterval(pollingIntervalInst);
    } catch (ex) {
      // do nothing
    }
    */
    postal.publish({
      topic: "websocket.initialized",
      data: {
        // this is the old sessionId
        sessionId: UserInfo.info.sessionId
      }
    });
    store.dispatch(setWebsocketOpened(true));
  };
  
  client.onmessage = onServerMessage;
  
  client.onerror = (error) => {
    console.log('WebSocket error: ' + stringify(error) + ' ' + JSON.stringify(error, ["message", "arguments", "type", "name"]));
    console.log('Falling back to polling...')
    
    webSocketError = true;
    store.dispatch(setWebsocketErrorMessage(stringify(error)));
    /* we should get a close event later on
    pollServerMessages = true;
    webSocketOpened = false;
    if ( typeof onErrorOrClose === 'function') {
      onErrorOrClose();
    }
    */
  };

  client.onclose = (event) => {
    console.log('WebSocket close, was clean: ' + event.wasClean);
    // the socket should be active all the time the app is running!
    // but server could have closed it, maybe session expired!
    pollServerMessages = true;
    webSocketOpened = false;
    store.dispatch(setWebsocketOpened(false));
    webSocketError = event.wasClean;
    if ( typeof onErrorOrClose === 'function') {
      onErrorOrClose();
    }
  };

  return client;
}

const reopenWebSocket = () => {
    setTimeout(() => {
        console.log("Reopening WebSocket...");
        SocketClient = openWebSocket(reopenWebSocket);
    }, 1000);
};


const netSocketSend = (message) => {
  
    if (webSocketOpened && SocketClient.readyState === SocketClient.OPEN) {
        const sendIntervalInst = setInterval(() => {
            if (SocketClient.readyState === SocketClient.OPEN) {
                if (SocketClient.bufferedAmount == 0) {
                    SocketClient.send(JSON.stringify(message));
                    clearInterval(sendIntervalInst);
                }
            } else {
                clearInterval(sendIntervalInst);
            }
        }, 100);

    }
  
 /*
 if ( webSocketOpened && SocketClient.connection.connected ) {
  SocketClient.connection.sendUTF(JSON.stringify(message));
 }
 */
}

// start polling first as websocket may take several minutes to fail
var pollingIntervalInst;
var SocketClient;
var keycloakInitialized = false;
var websocketInitialized = false;

const InitHooks = []; // each item is { name, hook, completed }

// this is obsolete, state should be represented in react-redux and used inside components using the appropriate hooks
// so UI will update propertly.
const runInitHooks = () => {
  const hooks = [ ...InitHooks];
  hooks.forEach( item => {
    if ( !item.completed ) {
      item.completed = true;
      if ( typeof item.hook === 'function' ) {
        item.hook();
      }
    }
  });
};

// Register an function to be run after authentication is done and we have full identity and access to the backend services
const registerInitHook = ( hookname, hook) => {
  let pitem = InitHooks.find( value => {
    return value.name === hookname;
  });
  if ( pitem ) {
    pitem.hook = hook;
  } else {
    pitem = {
      name: hookname,
      hook: hook,
      completed: false,
    };
    InitHooks.push(pitem);
  }
  if ( keycloakInitialized ) {
    if ( oidcEnabled ) {
      if ( webSocketOpened ) {
        runInitHooks();
      }
    } else {
      runInitHooks();
    }
  }

};

const AUTH_RETRIES = 5;
var authRetryAttempt = AUTH_RETRIES;

const cleanCookies = () => {
  document.cookie.split(";").forEach(function (cookie) {
    document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

const loadKeycloak = () => {
  // We now use hybrid OIDC mode on the server, so, by the time this runs,
  // there will be an authentication session in place and the Javascript
  // client will use SSO. We'll keep using the client side from
  // then on, since we can control it better.
  fetch('/config/ac')
    .then(response => response.json())
    .then(config => {
      // console.log('Auth config: ' + JSON.stringify(config));
      oidcEnabled = config.oidcEnabled;
      if (config.oidcEnabled) {
        keycloak = new Keycloak(config.kc);
        keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then((authenticated) => {

          if (authenticated) {
            // console.log('Keycloak subject: ' + keycloak.subject + ', roles= ' + JSON.stringify(keycloak.realmAccess));
            keycloak.loadUserProfile()
              .then((profile) => {

                UserProfile = { ...profile };
                UserProfile['roles'] = keycloak.realmAccess.roles;
                UserInfo.info.name = UserProfile.username;
                UserInfo.info.givenName = UserProfile.firstName;
                UserInfo.info.familyName = UserProfile.lastName;
                UserInfo.info.email = UserProfile.email;
                UserInfo.info.roles = UserProfile.roles;
                // add also properties for compatibility with UserInfo
                UserProfile['name'] = UserProfile.username;
                UserProfile['givenName'] =  UserProfile.firstName;
                UserProfile['familyName'] = UserProfile.lastName;
                console.log(JSON.stringify(UserProfile, null, "  "));

                keycloakInitialized = true;
                
                postal.publish({
                  topic: "keycloak.initialized",
                  data: {
                    user: UserProfile
                  }
                });
                store.dispatch(setOidcEnabled(true));
                store.dispatch(setUser(UserProfile));
                store.dispatch(setAuthToken(keycloak.token));
                store.dispatch(setAuthenticatedAsync(true)); // TEST
                store.dispatch(setKeycloakInitialized(true));
                

              }).catch(() => {
                console.log('Failed to load user profile');
              });
          } else {
            console.log('Not authenticated');
          }
        }).catch(error => {
          console.log('Failed to initialize authentication provider: ' + JSON.stringify(error));
          if ( authRetryAttempt > 0 ) {
            authRetryAttempt--;
            cleanCookies();
            loadKeycloak();
          }
        });
      } else {
        console.log('OIDC disabled');
        keycloakInitialized = true;
        store.dispatch(setKeycloakInitialized(true));
        store.dispatch(setFormEnabled(true));
        // leave it to the Login page
        // store.dispatch(setAuthenticatedAsync(true)); // TEST
        runInitHooks();
      }
    }).catch(error => {
      console.log('Failed to retrieve auth configuration' + error);
      if ( authRetryAttempt > 0 ) {
        authRetryAttempt--;
        cleanCookies();
        loadKeycloak();
      }
    });
}

loadKeycloak();

const SOCKET_PING_INTERVAL = 3;
var socketPingCount = -1;
const TOKEN_REFRESH_INTERVAL = 3;
var tokenRefreshCount = -1;
const TOKEN_REFRESH_EXPIRY = 10;
const NOTIFICATION_INTERVAL = 3;
var notificationCount = 0;

// this is a housekeeping periodic task, not only polling messages
const pollServer = () => {
  if ( !keycloakInitialized ) {
    return;
  }
  if ( !websocketInitialized && oidcEnabled ) {
    SocketClient = openWebSocket(reopenWebSocket);
    websocketInitialized = true;
  }
  // keepalive ping the websocket, not sure if it's needed,
  // the browser should be doing this at a lower protocol
  if ( webSocketOpened ) {
    socketPingCount--;
    if ( socketPingCount < 0 ) {
      socketPingCount = SOCKET_PING_INTERVAL;
      netSocketSend({ type: "ping" });
    }
  }
  // refresh the OIDC token if needed
  if (keycloakInitialized && oidcEnabled) {
    tokenRefreshCount--;
    if (tokenRefreshCount < 0) {
      tokenRefreshCount = TOKEN_REFRESH_INTERVAL;
      if (typeof keycloak.updateToken === 'function') {
        keycloak.updateToken(TOKEN_REFRESH_EXPIRY)
          .then((refreshed) => {
            if (refreshed) {
              console.log('Token was successfully refreshed');
              store.dispatch(setAuthToken(keycloak.token));
            } else {
              // console.log('Token is still valid');
            }
          }).catch(() => {
            console.log('Failed to refresh the token, or the session has expired');
          });
      }
    }
  }
  // retrieve notifications and process
  notificationCount--;
  if ( notificationCount < 0 ) {
    notificationCount = NOTIFICATION_INTERVAL;
    processNotifications();
  }

  if ( ! pollServerMessages ) {
      return;
  }
  // console.log('Fetching messages for ' + UserInfo.info.name);
  const auth = getAuthHeaders();
  const myheaders = { 'Cache-Control': 'no-cache, no-store, must-revalidate', Pragma: 'no-cache', Expires: '0', ...auth};
  fetch('/api/server/messages', { headers: myheaders})
      .then(response => response.json())
      .then(data => {
          if (Array.isArray(data)) {
              if (data.length > 0) {
                  console.log('Fetched ' + data.length + ' messages');
                  for (const m of data) {
                      processServerMessage(m);
                  }
              }
          }
      })
      .catch((error) => {
          console.error('Error fetching messages:', error);
      });
};

pollingIntervalInst = setInterval(pollServer, 2200);



export { SocketClient, UserInfo, UserProfile, getAuthHeaders, netGet, netPost, netFetch, netSocketSend, keycloak, registerInitHook };
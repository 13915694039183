import React, { Component, useState, useEffect, useCallback } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import postal from 'postal';

import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { RepeatOneSharp } from "@mui/icons-material";
import { Typography } from "@mui/material";

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import FlightIcon from '@mui/icons-material/Flight';
import PendingIcon from '@mui/icons-material/Pending';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Groups2Icon from '@mui/icons-material/Groups2';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';


import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";
import { openPdfDocument, openReadOnlyPdfDocument }  from "../webviewer";
import { MessageHooks } from "../../App";
import { DateTime, FixedOffsetZone } from "luxon";
import FileDownloader from "components/file-download";
import useVisibleOnScreen from "components/visible";

const VIEWER_READONLY = true;
const DLIB_ROOT = '/opt/UCS/dlib/';
const DATE_FORMAT = 'dd MMM yyyy ZZZZ';
var wreload = 0;

const ACL_PATH = [
    '/C/dlib', 
    '/C/dlib/current', 
    '/C/dlib/archive',
    '/C/dlib/inprogress',
    '/C/dlib/uploads',
];

const DLIB_INDEX = 0;
const CURRENT_INDEX = 1;
const ARCHIVE_INDEX = 2;
const INPROGRESS_INDEX = 3;
const UPLOADS_INDEX = 4;

const AREA_ICONS = {
    'SG' : <HealthAndSafetyIcon  fontSize="inherit" />,
};


/*
var lastCurrentMenu = '';
const menuSubscription = postal.subscribe({
    topic: "app.menu.change",
    callback: (data, envelope) => {
        console.log('DLIB GOT menu change: ' + JSON.stringify(data) + ',' + JSON.stringify(envelope));
        // updateLastCurrentMenu(data.menu);
        lastCurrentMenu = data.menu;
    }
});
*/

/*
function App () {
  const [sizes, setSizes] = useState([100, '30%', 'auto']);
 
  const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
 
  return (
    <div style={{ height: 500 }}>
      <SplitPane
        split='vertical'
        sizes={sizes}
        onChange={setSizes}
      >
        <Pane minSize={50} maxSize='50%'>
          <div style={{ ...layoutCSS, background: '#ddd' }}>
            pane1
          </div>
        </Pane>
        <div style={{ ...layoutCSS, background: '#d5d7d9' }}>
          pane2
        </div>
        <div style={{ ...layoutCSS, background: '#a1a5a9' }}>
          pane3
        </div>
      </SplitPane>
    </div>
  );
};
*/

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

function DownloadPdfDialog(props) {
    const {
        open,
        files,
        onCancel,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 900,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Download Edition Files</DialogTitle>
            <DialogContent>
                <FileDownloader files={files} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Close</Button>
            </DialogActions>
        </Dialog>

    );

}

function HtmlViewer(props) {
    const {
        source,
        ...other
    } = props;

    return (
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <iframe src={source} style={{position: 'relative', width: '100%', height: '100%'}}/>
        </div>
    );
}

function DlibPage(props) {
    const {
        select = 'CUR',
        shelf = 'DECK',  // DECK, HR (Human resources), areaId
        ...other
    } = props;

    const [doclist, setDoclist] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [timeZone, setTimeZone] = useState(0);
    const [dcrTree, setDcrTree] = useState([]);
    const [rejTree, setRejTree] = useState([]);
    const [oemList, setOemList] = useState([]);
    const [refList, setRefList] = useState([]);
    const [workList, setWorkList] = useState([]);
    const [workBinders, setWorkBinders] = useState([]);
    const [sizes, setSizes] = useState([100, '30%', 'auto']);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const [downloadFiles, setDownloadFiles] = useState([]);
    const theme = useTheme();

    useEffect( () => {
        netGet('/config/timezone')
            .then(response => response.json()) 
            .then(tzinfo => {
                setTimeZone(FixedOffsetZone.instance(tzinfo.offset));
                // console.log('Zone: ' + JSON.stringify(tzinfo));
                // setTimeZone(FixedOffsetZone.instance(12*60));
            }).catch(error => {
                console.log('Error fetching time zone info: ' + error);
            });
        updateDocuments(select, shelf);

    }, [select, shelf]);

    useEffect( () => {
        if ( selectedRow >= 0 ) {
            const doc = doclist[selectedRow];
            if ( doc && doc.uuid) {
                netGet(`/api/dlib/port/list?did=${doc.uuid}&name=DCR`)
                    .then(response => response.json())
                    .then(pfl => {
                        setDcrTree(pfl);
                    }).catch(error => {
                        console.log('Error fetching DCRs info: ' + error);
                    });
                netGet(`/api/dlib/port/list?did=${doc.uuid}&name=DCR_REJ`)
                    .then(response => response.json())
                    .then(pfl => {
                        setRejTree(pfl);
                    }).catch(error => {
                        console.log('Error fetching Rejected DCRs info: ' + error);
                    });
                // working area
                netGet(`/api/dlib/port/list?did=${doc.uuid}&name=WORK`)
                    .then(response => response.json())
                    .then(pfl => {
                        console.log('WORK AREA: ' + JSON.stringify(pfl));
                        // const opfl = pfl.filter(item => item.properties?.CATEG !== 'FORM_DCR');
                        setWorkList(pfl);
                        setWorkBinders([]);
                        pfl.forEach(pf => {
                            netGet(`/api/dlib/binder/list?did=${doc.uuid}&pid=${pf.id}`)
                                .then(bresp => bresp.json())
                                .then(bl => {
                                    if (Array.isArray(bl)) {
                                        bl.forEach(binder => binder.folio = pf);
                                        const wbl = [...workBinders, ...bl];
                                        setWorkBinders(wbl);
                                        // console.log('WORK AREA BINDERS = ' + JSON.stringify(wbl));
                                    }
                                }).catch(berr => {
                                    console.log('Error fetching work binders: ' + berr);
                                });
                        });
                    }).catch(error => {
                        console.log('Error fetching Work Area info: ' + error);
                    });
                // OEM revision
                netGet(`/api/dlib/port/list?did=${doc.uuid}&name=OEM`)
                    .then(response => response.json())
                    .then(pfl => {
                        setOemList(pfl);
                    }).catch(error => {
                        console.log('Error fetching Work Area info: ' + error);
                    });
                // Additional References
                netGet(`/api/dlib/port/list?did=${doc.uuid}&name=REF`)
                    .then(response => response.json())
                    .then(pfl => {
                        setRefList(pfl);
                    }).catch(error => {
                        console.log('Error fetching Work Area info: ' + error);
                    });
            }
        }

    }, [selectedRow, doclist]);

    const updateDocuments = (sel, shelf) => {
        let turl = sel === 'ARC' ? '/api/dlib/list/archive' : '/api/dlib/list/current';
        if ( sel === 'OPEN' ) {
            turl = '/api/dlib/list/open';
        }
        netGet(turl)
            .then(response => response.json())
            .then(docs => {
                if (Array.isArray(docs)) {
                    // console.log('DL raw list = ' + JSON.stringify(docs));
                    const rdocs = [];
                    docs.forEach( doc => {
                        const dname = doc.name.toUpperCase();
                        // console.log('CHECK DOC: ' + dname + ' : shelf = ' + shelf);
                        if ( doc.properties?.areaId ) {
                            if ( doc.properties.areaId === shelf ) {
                                rdocs.push(doc);
                            }
                        } else if (shelf === 'DECK' && (dname.includes('QRH') || dname.includes('FCOM') || dname.includes('DDG'))) {
                            rdocs.push(doc);
                        } else if ( shelf === 'HR' && !doc.properties?.areaId && !(dname.includes('QRH') || dname.includes('FCOM') || dname.includes('DDG'))) {
                            rdocs.push(doc);
                        }

                    });
                     
                    setDoclist(rdocs);
                    if ( selectedRow >= rdocs.length  ) {
                        setSelectedRow(-1);
                    }
                    // console.log('dlib list: ' + JSON.stringify(rdocs.length));
                }
            }).catch(error => {
                console.log('Error fetching Digital Library collection: ' + error);
                setDoclist([]);
                setSelectedRow(-1);
            });
    };

    const dlibInfo = (message) => {
        let dlevent = message.data;
        console.log('Received DLib message: ' + dlevent.action +  ' : ' + dlevent.id);
        if (dlevent.action === 'MODIFIED') {
            updateDocuments(select);
        }
    };    
    
    MessageHooks["dlib"]["DlibPage"] = dlibInfo;

    const handleRowClicked = (event,index) => {
        setSelectedRow(index);
        console.log('DOC: ' + JSON.stringify(doclist[index].path));
    };

    const formatDate = (date) => {
        if ( ! date ) {
            return '-';
        }
        const sd = DateTime.fromISO(date, { zone: timeZone});
        let offset = 0;
        if ( sd.hour !== 0 ) {
            // adjust for the TZ when closed
            if ( sd.hour > 12 ) {
                offset = 24 - sd.hour ;
            } else {
                offset = - sd.hour;
            }
            // console.log('TZ ' + JSON.stringify(timeZone));
            const zoffset = timeZone.fixed + offset * 60;
            // console.log('Time zone offset: ' + sd.hour + ' : ' + zoffset);
            const zone = FixedOffsetZone.instance(zoffset);
            const zd = DateTime.fromISO(date, {zone: zone});
            return zd.toFormat(DATE_FORMAT);
        }
        return sd.toFormat(DATE_FORMAT);
    };

    const openPdfFile = (event, file) => {
        // console.log('Detail = ' + event.detail);
        if ( event.detail > 1 && doclist[selectedRow]?.path ) {
            const fpath = DLIB_ROOT + doclist[selectedRow].path + '/' + file.path;
            // console.log('Opening file: ' + fpath);
            if ( VIEWER_READONLY ) {
                openReadOnlyPdfDocument(fpath, file.name);
            } else {
                openPdfDocument(fpath, file.name);
            }
        }
    };

    const openDcrFile = (event, file, folio) => {
        if ( event.detail > 1 && folio.properties?.document?.path ) {
            const fpath = DLIB_ROOT + folio.properties.document.path + '/' + file.path;
            // console.log('Opening file: ' + fpath);
            if ( VIEWER_READONLY ) {
                openReadOnlyPdfDocument(fpath, file.name);
            } else {
                openPdfDocument(fpath, file.name);
            }    
        }
    };

    const openHtmlFile = (event, file) => {
        if ( event.detail > 1 && doclist[selectedRow]?.path ) {
            const fpath = DLIB_ROOT + doclist[selectedRow].path + '/' + file.path;
            
            if ( fpath.endsWith('.zip') ) {
                const hpath = encodeURI('/file/' + UserInfo.info.sessionId + fpath.slice(0, fpath.length - 4) + '/');
                console.log('Opening HTML file: ' + hpath);
                DlibModule.pageChange(<HtmlViewer source={hpath} />);
            }
        }
    };

    const hasDrafts = (outfiles) => {
        if ( Array.isArray(outfiles) ) {
            return outfiles.some( item => item.type == 'DRAFT');
        }
        return false;
    };

    const hasCandidateDrafts = (outfiles) => {
        if ( Array.isArray(outfiles) ) {
            return outfiles.some( item => item.type == 'CANDIDATE');
        }
        return false;
    };

    const hasEditions = (outfiles) => {
        if ( Array.isArray(outfiles) ) {
            return outfiles.some( item => item.type == 'FINAL');
        }
        return false;
    };

    const downloadPdf = (outputs) => {
        if ( Array.isArray(outputs) && doclist[selectedRow]?.path ) {
            const bpath = DLIB_ROOT + doclist[selectedRow].path + '/';
            // const oo = outputs.length > 2 ? outputs.slice(0,2) : outputs;
            const ff = outputs.filter(item => item.contentType.toLowerCase().includes('pdf') && item.type == 'FINAL').sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                // console.log('file: ' + file.path);
                return { url: encodeURI('/file/' + UserInfo.info.sessionId + bpath + file.path), name: file.name};
                // return (<TreeItem itemId={`dlib-${file.name}`} label={file.name} onClick={event => openPdfFile(event, file)} />);
            });
            const oo = ff.length > 3 ? ff.slice(0,3) : ff;
            console.log('Download files: ' + JSON.stringify(oo));
            setDownloadFiles(oo);
            setDownloadOpen(true);
        }
    };

    const compareDcrFiles = (a, b) => {
        const ta = a.type ? a.type : 'FORM';
        const tb = b.type ? b.type : 'FORM';
        if ( ta !== tb ) {
            if ( ta === 'FORM' ) {
                return -1;
            } else {
                return 1;
            }
        } else if ( a.parentFormId && !b.parentFormId ) {
            return 1;
        } else if ( !a.parentFormId && b.parentFormId ) {
            return -1;
        }

        const na = a.name ? a.name : '';
        const nb = b.name ? b.name : '';
        return na.localeCompare(nb);
    };

    const getDcrPortafolioBinders = (dcr) => {
        if ( !Array.isArray(dcr.properties?.files) ) {
            return [];
        }
        const binders = [];
        dcr.properties.files.forEach( item => {
            let formId;
            let formTitle = item.properties?.title;
            if ( ! formTitle ) {
                formTitle = item.properties?.formTitle;
            }
            if ( item.properties?.parentFormId ) {
                formId = item.properties?.parentFormId;
            } else if ( item.properties?.formId ) {
                formId = item.properties?.formId;
            }
            if (formId) {
                let binder = binders.find(b => b.id === formId);
                if (!binder) {
                    binder = {
                        id: formId,
                        title: formTitle,
                        files: [],
                    };
                    binders.push(binder);
                }
                item.folio = dcr;
                binder.files.push(item);
                // console.log('Binder: ' + JSON.stringify(binder));
            } 
            /*
            else {
                console.log('DCR formId: ' + formId + ', title=' + formTitle);
                console.log('DCR file: ' + JSON.stringify(item));
            }
            */
        });
        return binders;
    };

    const getDcrPortafolioOtherFiles = (files) => {
        if ( !Array.isArray(files) ) {
            return [];
        }
        const other = [];
        files.forEach( item => {
            if (! item.properties?.formId) {
                other.push(item);
            } 
        });
        return other;
    };

    const getWorkListLength = (wlist) => {
        let n = 0;
        if ( Array.isArray(wlist) ) {
            wlist.forEach( work => {
                if ( Array.isArray(work.properties?.files) ) {
                    n += work.properties.files.length;
                }
            });
        }
        return n;
    };

    const hasDocumentOutputs = (docs,ext,ftype='FINAL') => {
        if ( Array.isArray(docs) && docs.length > 0 ) {
            const index = docs.findIndex(item => item.contentType.toLowerCase().includes(ext) && item.type == ftype);
            return index !== -1;
        }
        return false;
    }

    const filterDocumentOutputs = (docs,ext,ftype='FINAL') => {
        return docs.filter(item => item.contentType.toLowerCase().includes(ext) && item.type == ftype).sort((a, b) => a.name.localeCompare(b.name));
    }

    return (
        <SplitPane
        split='horizontal'
        sizes={sizes}
        onChange={setSizes}
      >
            <Pane minSize={40} maxSize='60%'>
                <Box sx={{ height: '100%', position: 'relative', overflow: 'auto'  }}>
                    <TableContainer component={Paper} sx={{ padding: 1, paddingTop: 2, paddingBottom: 2 }}>
                        <Table stickyHeader sx={{ minWidth: 350 }} aria-label="current library table" size="small">
                            <TableHead>
                                <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Document Identifier</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Revision Number</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Revision Date</TableCell>
                                </TableRow>
                            </TableHead>
                            {Array.isArray(doclist) && doclist.length > 0 &&
                                <TableBody>
                                    {doclist.map((row, index) => (
                                        <TableRow
                                            key={`${row.name}-${index}`}
                                            onClick={(event) => handleRowClicked(event, index)}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                            selected={index === selectedRow}
                                        >
                                            <TableCell component="th" scope="row" sx={{ width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '12em' }}>{row.revisionNumber}</TableCell>
                                            <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{formatDate(row.revisionDate)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                </Box>
            </Pane>
            
            <Box sx={{ height: '100%', position: 'relative', overflow: 'auto', borderTopColor: theme.palette.divider, borderTopStyle: 'solid', borderTopWidth: '2px'}} >
                {/* <Divider orientation="horizontal"/> */}
                {selectedRow >= 0 &&
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="4em 4em 1fr" gap={1} sx={{ padding: 3, height: '100%' }}>
                        <Box gridColumn="span 4">
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Identifier</Typography>
                            </Box>
                            <Box>
                                <Typography>{doclist[selectedRow].name}</Typography>
                            </Box>
                        </Box>
                        <Box gridColumn="span 4">
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Revision Number</Typography>
                            </Box>
                            <Box>
                                <Typography>{doclist[selectedRow].revisionNumber}</Typography>
                            </Box>
                        </Box>
                        <Box gridColumn="span 4">
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Revision Date</Typography>
                            </Box>
                            <Box>
                                <Typography>{formatDate(doclist[selectedRow].revisionDate)}</Typography>
                            </Box>
                        </Box>
                        <Box gridColumn="span 8" >
                            <Box sx={{ paddingTop: 2 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Title</Typography>
                            </Box>
                            <Box>
                                <Typography>{doclist[selectedRow].title}</Typography>
                            </Box>
                        </Box>
                        <Box gridColumn="span 4">
                            {doclist[selectedRow].closeDate &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Date Closed</Typography>
                                    </Box>
                                    <Box>
                                        <Typography>{formatDate(doclist[selectedRow].closeDate)}</Typography>
                                    </Box>
                                </React.Fragment>}
                        </Box>
                        <Box gridColumn="span 12" sx={{ height: '100%' }}>
                            {Array.isArray(workList) && getWorkListLength(workList) > 0 &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Working Area</Typography>
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            {Array.isArray(workBinders) && workBinders.length > 0 &&
                                                workBinders.sort((a, b) => a.name?.localeCompare(b.name)).map((dcr, index) =>
                                                <TreeItem itemId={`dlib-work-b-dcr-${index}`} label={`${dcr.name}: ${dcr.description}`}>
                                                    {Array.isArray(dcr.properties?.files) &&
                                                        dcr.properties.files.sort((a, b) => compareDcrFiles(a,b)).map(file => {
                                                            // console.log('file: ' + file.path);
                                                            const oid = getRandomInt(10000);
                                                            return (<TreeItem itemId={`dlib-work-b-dcr-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openDcrFile(event, file, dcr.folio)} />);
                                                        })
                                                    }
                                                </TreeItem>)
                                            }
                                            {Array.isArray(workList) &&
                                                workList.sort((a, b) => a.properties?.document?.revisionNumber?.localeCompare(b.properties?.document?.revisionNumber)).map((work, index) =>
                                                    <React.Fragment>
                                                        {Array.isArray(work.properties?.files) &&
                                                            work.properties.files.filter(item => item.properties?.categ !== 'FORM_DCR').sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                                                                // console.log('file: ' + file.path);
                                                                const oid = getRandomInt(10000);
                                                                return (<TreeItem itemId={`dlib-work-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openDcrFile(event, file, work)} />);
                                                            })
                                                        }
                                                    </React.Fragment>)
                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                            {Array.isArray(oemList) && oemList.length > 0 &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>OEM Revision</Typography>
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            {Array.isArray(oemList) &&
                                                oemList.sort((a, b) => a.properties?.document?.revisionNumber?.localeCompare(b.properties?.document?.revisionNumber)).map((oem, index) =>
                                                    <React.Fragment>
                                                        {Array.isArray(oem.properties?.files) &&
                                                            oem.properties.files.sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                                                                // console.log('file: ' + file.path);
                                                                const oid = getRandomInt(10000);
                                                                return (<TreeItem itemId={`dlib-oem-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openDcrFile(event, file, oem)} />);
                                                            })
                                                        }
                                                    </React.Fragment>)
                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                            {Array.isArray(dcrTree) && dcrTree.length > 0 &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Approved Document Change Requests</Typography>
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            {Array.isArray(dcrTree) && dcrTree.length > 0 &&
                                                dcrTree.sort((a, b) => a.properties?.document?.revisionNumber?.localeCompare(b.properties?.document?.revisionNumber)).map((dcr, index) =>
                                                    <TreeItem itemId={`dlib-1-dcr-${index}`} label={`Revision ${dcr.properties?.document?.revisionNumber}`}>
                                                        {getDcrPortafolioBinders(dcr).map( (binder,bindex) => 
                                                            <TreeItem itemId={`dlib-dcr-${select}-${shelf}-binder-${binder.id}-${bindex}`} label={`${binder.id}: ${binder.title}`}  >
                                                                {Array.isArray(binder.files) &&
                                                                    binder.files.map(file => {
                                                                        // console.log('file: ' + file.path);
                                                                        const oid = getRandomInt(10000);
                                                                        return (<TreeItem itemId={`dlib-binder-dcr-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openDcrFile(event, file, dcr)} />);
                                                                    })
                                                                }
                                                            </TreeItem>
                                                        )
                                                        }
                                                        { Array.isArray(dcr.properties?.files) &&
                                                            getDcrPortafolioOtherFiles(dcr.properties.files).sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                                                                // console.log('file: ' + file.path);
                                                                const oid = getRandomInt(10000);
                                                                return (<TreeItem itemId={`dlib-dcr-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openDcrFile(event, file, dcr)} />);
                                                            })
                                                        }
                                                    </TreeItem>)
                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                            {Array.isArray(rejTree) && rejTree.length > 0 &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Rejected Document Change Requests</Typography>
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            {Array.isArray(rejTree) && rejTree.length > 0 &&
                                                rejTree.sort((a, b) => a.properties?.document?.revisionNumber?.localeCompare(b.properties?.document?.revisionNumber)).map((dcr, index) =>
                                                    <TreeItem itemId={`dlib-2-rej-${index}`} label={`Revision ${dcr.properties?.document?.revisionNumber}`}>
                                                        {getDcrPortafolioBinders(dcr).map( (binder,bindex) => 
                                                            <TreeItem itemId={`dlib-rej-${select}-${shelf}-binder-${binder.id}-${bindex}`} label={`${binder.id}: ${binder.title}`}  >
                                                                {Array.isArray(binder.files) &&
                                                                    binder.files.map(file => {
                                                                        // console.log('file: ' + file.path);
                                                                        const oid = getRandomInt(10000);
                                                                        return (<TreeItem itemId={`dlib-binder-rej-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openDcrFile(event, file, dcr)} />);
                                                                    })
                                                                }
                                                            </TreeItem>
                                                        )
                                                        }
                                                        { Array.isArray(dcr.properties?.files) &&
                                                            getDcrPortafolioOtherFiles(dcr.properties.files).sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                                                                // console.log('file: ' + file.path);
                                                                const oid = getRandomInt(10000);
                                                                return (<TreeItem itemId={`dlib-dcr-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openDcrFile(event, file, dcr)} />);
                                                            })
                                                        }
                                                    </TreeItem>)
                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                            {Array.isArray(refList) && refList.length > 0 &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>References</Typography>
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            {Array.isArray(refList) &&
                                                refList.sort((a, b) => a.properties?.document?.revisionNumber?.localeCompare(b.properties?.document?.revisionNumber)).map((ref, index) =>
                                                    <React.Fragment>
                                                        {Array.isArray(ref.properties?.files) &&
                                                            ref.properties.files.sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                                                                // console.log('file: ' + file.path);
                                                                const oid = getRandomInt(10000);
                                                                return (<TreeItem itemId={`dlib-ref-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openDcrFile(event, file, ref)} />);
                                                            })
                                                        }
                                                    </React.Fragment>)
                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                            {doclist[selectedRow] && hasDrafts(doclist[selectedRow].outputs) &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Approval Drafts</Typography>
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            {Array.isArray(doclist[selectedRow].outputs) &&
                                                doclist[selectedRow].outputs.filter(item => item.contentType.toLowerCase().includes('pdf') && item.type == 'DRAFT').sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                                                    // console.log('file: ' + file.path);
                                                    const oid = getRandomInt(10000);
                                                    return (<TreeItem itemId={`dlib-ad-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openPdfFile(event, file)} />);
                                                }
                                                )
                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                            {doclist[selectedRow] && hasCandidateDrafts(doclist[selectedRow].outputs) &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Candidate Draft</Typography>
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            {Array.isArray(doclist[selectedRow].outputs) &&
                                                doclist[selectedRow].outputs.filter(item => item.contentType.toLowerCase().includes('pdf') && item.type == 'CANDIDATE').sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                                                    // console.log('file: ' + file.path);
                                                    const oid = getRandomInt(10000);
                                                    return (<TreeItem itemId={`dlib-cd-${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openPdfFile(event, file)} />);
                                                }
                                                )
                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                            {doclist[selectedRow] && hasEditions(doclist[selectedRow].outputs) &&
                                <React.Fragment>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box sx={{ paddingTop: 2 }}>
                                            <Typography sx={{ fontWeight: 'bold' }}>Editions</Typography>
                                        </Box>
                                        {/* <Button  variant="outlined" onClick={() => downloadPdf(doclist[selectedRow].outputs)}>Download</Button> */}
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            { hasDocumentOutputs(doclist[selectedRow].outputs, '.document') &&
                                                 <TreeItem itemId="dlib-1-docx" label="Source">
                                                    {filterDocumentOutputs(doclist[selectedRow].outputs, '.document').map(file => {
                                                        const oid = getRandomInt(10000);
                                                        return (<TreeItem itemId={`dlib--${select}-${shelf}-${file.name}-${oid}`} label={file.name}  />) ;
                                                    })}
                                                 </TreeItem>

                                            }
                                            { hasDocumentOutputs(doclist[selectedRow].outputs, 'html') &&
                                                 <TreeItem itemId="dlib-1-html" label="HTML">
                                                    {filterDocumentOutputs(doclist[selectedRow].outputs, 'html').map(file => {
                                                        const oid = getRandomInt(10000);
                                                        return (<TreeItem itemId={`dlib--${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openHtmlFile(event, file)} />) ;
                                                    })}
                                                 </TreeItem>

                                            }
                                            { hasDocumentOutputs(doclist[selectedRow].outputs, 'pdf') &&
                                                 <TreeItem itemId="dlib-1-pdf" label="PDF">
                                                    {filterDocumentOutputs(doclist[selectedRow].outputs, 'pdf').map(file => {
                                                        const oid = getRandomInt(10000);
                                                        return (<TreeItem itemId={`dlib--${select}-${shelf}-${file.name}-${oid}`} label={file.name} onClick={event => openPdfFile(event, file)} />) ;
                                                    })}
                                                 </TreeItem>

                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                        </Box>
                    </Box>

                }
                <DownloadPdfDialog files={downloadFiles} open={downloadOpen} onCancel={() => setDownloadOpen(false)} />
            </Box>
        </SplitPane>

    );

}

function DlibUploadPage(props) {
    const {
        select = 'CUR',
        ...other
    } = props;

    const [doclist, setDoclist] = useState([]);
    const [doc, setDoc] = useState(null);
    const [shelfList, setShelfList] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [timeZone, setTimeZone] = useState(0);
    const [dcrTree, setDcrTree] = useState([]);
    const [oemList, setOemList] = useState([]);
    const [workList, setWorkList] = useState([]);
    const [sizes, setSizes] = useState([100, '30%', 'auto']);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const [downloadFiles, setDownloadFiles] = useState([]);
    const theme = useTheme();

    useEffect( () => {
        netGet('/config/timezone')
            .then(response => response.json()) 
            .then(tzinfo => {
                setTimeZone(FixedOffsetZone.instance(tzinfo.offset));
                // console.log('Zone: ' + JSON.stringify(tzinfo));
                // setTimeZone(FixedOffsetZone.instance(12*60));
            }).catch(error => {
                console.log('Error fetching time zone info: ' + error);
            });
        updateDocuments(select);

    }, [select]);

    /*
    useEffect( () => {
        if ( selectedRow >= 0 ) {
            const pf = shelfList[selectedRow];
            if ( pf != null ) {

            }
            
            if ( doc && doc.uuid) {
                netGet(`/api/dlib/port/list?did=${doc.uuid}&name=DCR`)
                    .then(response => response.json())
                    .then(pfl => {
                        setDcrTree(pfl);
                    }).catch(error => {
                        console.log('Error fetching DCRs info: ' + error);
                    });
                // working area
                netGet(`/api/dlib/port/list?did=${doc.uuid}&name=WORK`)
                    .then(response => response.json())
                    .then(pfl => {
                        setWorkList(pfl);
                    }).catch(error => {
                        console.log('Error fetching Work Area info: ' + error);
                    });
                // OEM revision
                netGet(`/api/dlib/port/list?did=${doc.uuid}&name=OEM`)
                    .then(response => response.json())
                    .then(pfl => {
                        setOemList(pfl);
                    }).catch(error => {
                        console.log('Error fetching Work Area info: ' + error);
                    });
            }
            
        }

    }, [selectedRow, doclist]);
    */

    const updateDocuments = (sel) => {
        const turl = '/api/dlib/doc?name=uploads&sts=UPLOAD';
        netGet(turl)
            .then(response => response.json())
            .then(data => {
                setDoc(data);
                console.log('Got uploads doc = ' + JSON.stringify(data));
                netGet('/api/dlib/port/list?did=' + data.uuid)
                    .then(response => response.json())
                    .then(pfl => {
                        console.log('shelfs:' + JSON.stringify(pfl));
                        setShelfList(pfl);
                    }).catch(error => {
                        console.log('Error fetching shelfs info: ' + error);
                    });
                    
            }).catch(error => {
                console.log('Error fetching Digital Library collection: ' + error);
                setDoc(null);
                setSelectedRow(-1);
                setShelfList([]);
            });
    };

    const dlibInfo = (message) => {
        let dlevent = message.data;
        console.log('Received DLib message: ' + dlevent.action +  ' : ' + dlevent.id);
        if (dlevent.action === 'MODIFIED') {
            updateDocuments(select);
        }
    };    
    
    MessageHooks["dlib"]["DlibUploadPage"] = dlibInfo;

    const handleRowClicked = (event,index) => {
        setSelectedRow(index);
        console.log('PORT: ' + JSON.stringify(shelfList[index].name));
    };

    const formatDate = (date) => {
        if ( ! date ) {
            return '-';
        }
        const sd = DateTime.fromISO(date, { zone: timeZone});
        let offset = 0;
        if ( sd.hour !== 0 ) {
            // adjust for the TZ when closed
            if ( sd.hour > 12 ) {
                offset = 24 - sd.hour ;
            } else {
                offset = - sd.hour;
            }
            // console.log('TZ ' + JSON.stringify(timeZone));
            const zoffset = timeZone.fixed + offset * 60;
            // console.log('Time zone offset: ' + sd.hour + ' : ' + zoffset);
            const zone = FixedOffsetZone.instance(zoffset);
            const zd = DateTime.fromISO(date, {zone: zone});
            return zd.toFormat(DATE_FORMAT);
        }
        return sd.toFormat(DATE_FORMAT);
    };

    const openPdfFile = (event, file) => {
        // console.log('Detail = ' + event.detail);
        if ( event.detail > 1 && doclist[selectedRow]?.path ) {
            const fpath = DLIB_ROOT + doclist[selectedRow].path + '/' + file.path;
            // console.log('Opening file: ' + fpath);
            if ( VIEWER_READONLY ) {
                openReadOnlyPdfDocument(fpath, file.name);
            } else {
                openPdfDocument(fpath, file.name);
            }
        }
    };

    const openDcrFile = (event, file, folio) => {
        if ( event.detail > 1 && folio.properties?.document?.path ) {
            const fpath = DLIB_ROOT + folio.properties.document.path + '/' + file.path;
            // console.log('Opening file: ' + fpath);
            if ( VIEWER_READONLY ) {
                openReadOnlyPdfDocument(fpath, file.name);
            } else {
                openPdfDocument(fpath, file.name);
            }    
        }
    };

    const openHtmlFile = (event, file) => {
        if ( event.detail > 1 && doclist[selectedRow]?.path ) {
            const fpath = DLIB_ROOT + doclist[selectedRow].path + '/' + file.path;
            
            if ( fpath.endsWith('.zip') ) {
                const hpath = encodeURI('/file/' + UserInfo.info.sessionId + fpath.slice(0, fpath.length - 4) + '/');
                console.log('Opening HTML file: ' + hpath);
                DlibModule.pageChange(<HtmlViewer source={hpath} />);
            }
        }
    };

   
    return (
        <SplitPane
        split='horizontal'
        sizes={sizes}
        onChange={setSizes}
      >
            <Pane minSize={40} maxSize='60%'>
                <Box sx={{ height: '100%', position: 'relative', overflow: 'auto'  }}>
                    <TableContainer component={Paper} sx={{ padding: 1, paddingTop: 2, paddingBottom: 2 }}>
                        <Table stickyHeader sx={{ minWidth: 350 }} aria-label="current library table" size="small">
                            <TableHead>
                                <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Shelf Identifier</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            {Array.isArray(shelfList) && shelfList.length > 0 &&
                                <TableBody>
                                    {shelfList.sort((a, b) => b.name.localeCompare(a.name)).map((row, index) => (
                                        <TableRow
                                            key={`${row.name}-${index}`}
                                            onClick={(event) => handleRowClicked(event, index)}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                            selected={index === selectedRow}
                                        >
                                            <TableCell component="th" scope="row" sx={{ minWidth: '6em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="td" scope="row" sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                                                {row.description}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                </Box>
            </Pane>
            
            <Box sx={{ height: '100%', position: 'relative', overflow: 'auto', borderTopColor: theme.palette.divider, borderTopStyle: 'solid', borderTopWidth: '2px'}} >
                {/* <Divider orientation="horizontal"/> */}
                {selectedRow >= 0 &&
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="4em 4em 1fr" gap={1} sx={{ padding: 3, height: '100%' }}>
                        <Box gridColumn="span 4">
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Identifier</Typography>
                            </Box>
                            <Box>
                                <Typography>{shelfList[selectedRow].name}</Typography>
                            </Box>
                        </Box>
                        <Box gridColumn="span 8">
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}>Description</Typography>
                            </Box>
                            <Box>
                                <Typography>{shelfList[selectedRow].description}</Typography>
                            </Box>
                        </Box>
                        <Box gridColumn="span 12" sx={{ height: '100%' }}>
                            {selectedRow >= 0 &&
                                <React.Fragment>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Files</Typography>
                                    </Box>
                                    <Box>
                                        <SimpleTreeView>
                                            {Array.isArray(shelfList[selectedRow].properties?.files) &&
                                                shelfList[selectedRow].properties.files.sort((a, b) => a.name.localeCompare(b.name)).map(file => {
                                                    // console.log('file: ' + file.path);
                                                    return (<TreeItem itemId={`dlib-work-${file.name}`} label={file.name} onClick={event => openDcrFile(event, file, shelfList[selectedRow])} />);
                                                })
                                            }
                                        </SimpleTreeView>
                                    </Box>
                                </React.Fragment>
                            }
                        </Box>
                    </Box>

                }
                <DownloadPdfDialog files={downloadFiles} open={downloadOpen} onCancel={() => setDownloadOpen(false)} />
            </Box>
        </SplitPane>

    );

}

function DlibMenu(props) {

    const {
        reload,
        ...other
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [pendingUpdates, setPendingUpdates] = useState(false);
    const theme = useTheme();
    const [isVisible, containerRef] = useVisibleOnScreen();
    const [menuACL, setMenuACL] = useState([]);
    const [extraAreas, setExtraAreas] = useState([]);

    useEffect(() => {
        // console.log('DLIB VISIBLE: ' + isVisible);
        if ( isVisible ) {
            console.log('Posting CurrentMenu = dlib...');
            // store.dispatch(setCurrentMenu('workflow'));
            postal.publish({
                topic: "app.menu.change",
                data: {
                    menu: 'dlib',
                }
            });
            if ( pendingUpdates ) {
                setPendingUpdates(false);
                // loadUpdates();
            }
            readACLs();
            getExtraAreas();
        }
    }, [isVisible]);

    const readACLs = () => {
        const aclm = [];
        const aparams = [];
        ACL_PATH.forEach( aclPath => {
            aparams.push({
                path: aclPath,
                action: 'BROWSE',
                subjects: UserInfo.info.roles,
            });
            aparams.push({
                path: aclPath,
                action: 'READ',
                subjects: UserInfo.info.roles,
            });
            /*
            aparams.push({
                path: aclPath,
                action: 'WRITE',
                subjects: UserInfo.info.roles,
            });
            */
        });
        netPost('/api/auth/acl/eval/multi', {
            params: aparams
        }).then(response => {
            if (!response.ok) {
                throw new Error('Status: ' + response.status);
            }
            return response.json();
        })
            .then(data => {
                // console.log('ACL Result [MANAGEMENT] = ' + JSON.stringify(data));
                if (Array.isArray(data)) {
                    for (let i = 0; i < data.length; i += 2) {
                        aclm.push({
                            hide: !data[i].result,
                            disabled: !data[i + 1].result,
                            // write: data[i + 2].result,
                        });
                    }

                    setMenuACL(aclm);
                    // console.log('MNGNT MENU ACL: ' + JSON.stringify(aclm));
                }

            }).catch(error => {
                console.log('Error: ' + error);
            });
    }

    const getExtraAreas = () => {
        setExtraAreas([]);
        netGet('/api/dlib/list/current')
            .then(response => response.json())
            .then(docs => {
                if (Array.isArray(docs)) {
                    const exa = [];
                    const exaIds = [];
                    docs.forEach(doc => {
                        if (doc.properties?.area && doc.properties?.areaId) {
                            if (!exaIds.includes(doc.properties.areaId) && doc.properties.areaId !== 'HR' && doc.properties.areaId !== 'DECK') {
                                exa.push({
                                    id: doc.properties.areaId,
                                    label: doc.properties.area,
                                });
                                exaIds.push(doc.properties.areaId);
                            }
                        }
                    });
                    console.log('Got EXTRA Areas: ' + JSON.stringify(exa));
                    setExtraAreas(exa);
                }
            }).catch(error => {
                setExtraAreas([]);
            });
    };

    const handleCurrentFlightDeckAction = () => {
        DlibModule.pageChange(<DlibPage select="CUR" />);
        setSelectedIndex(0);
    };

    const handleCurrentHRAction = () => {
        DlibModule.pageChange(<DlibPage select="CUR" shelf="HR" />);
        setSelectedIndex(10);
    };

    const handleCurrentExtraAction = (areaId, index) => {
        DlibModule.pageChange(<DlibPage select="CUR" shelf={areaId} />);
        setSelectedIndex(index);
    };

    const handleArchiveExtraAction = (areaId, index) => {
        DlibModule.pageChange(<DlibPage select="ARC" shelf={areaId} />);
        setSelectedIndex(index);
    };

    const handlePendingExtraAction = (areaId, index) => {
        DlibModule.pageChange(<DlibPage select="OPEN" shelf={areaId} />);
        setSelectedIndex(index);
    };

    const handleArchiveFlightDeckAction = () => {
        DlibModule.pageChange(<DlibPage select="ARC" />);
        setSelectedIndex(1);
    };

    const handleArchiveHRAction = () => {
        DlibModule.pageChange(<DlibPage select="ARC" shelf="HR" />);
        setSelectedIndex(11);
    };

    const handlePendingFlightDeckAction = () => {
        DlibModule.pageChange(<DlibPage select="OPEN" />);
        setSelectedIndex(2);
    };

    const handlePendingHRAction = () => {
        DlibModule.pageChange(<DlibPage select="OPEN" shelf="HR" />);
        setSelectedIndex(12);
    };

    const handleUploadShelfAction = () => {
        DlibModule.pageChange(<DlibUploadPage />);
        setSelectedIndex(3);
    };

    return (
        <Box ref={containerRef}
            sx={{ width: '100%', fontSize: '1.3em', padding: 0, position: "relative" }}
            role="presentation"
        >
            <List  >
                <ListItem key="cur-dlib" disablePadding 
                    sx={{display: Array.isArray(menuACL) && CURRENT_INDEX < menuACL.length && menuACL[CURRENT_INDEX].hide ? "none" : "block"}} >
                    <ListItemButton sx={{}} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <LocalLibraryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Current Collection" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                    
                </ListItem>
                <List sx={{ paddingLeft: '40px', paddingTop: 0, display: Array.isArray(menuACL) && CURRENT_INDEX < menuACL.length && menuACL[CURRENT_INDEX].hide ? "none" : "block"}}>
                    <ListItem key="cur-dlib-deck" disablePadding >
                        <ListItemButton onClick={handleCurrentFlightDeckAction} selected={selectedIndex === 0}
                            disabled={Array.isArray(menuACL) && CURRENT_INDEX < menuACL.length && menuACL[CURRENT_INDEX].disabled} >
                            <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                <FlightIcon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText primary="Flight Deck" primaryTypographyProps={{
                                fontSize: 17,
                                fontWeight: theme.typography.fontWeightMedium,
                                letterSpacing: 0,
                            }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="cur-dlib-hr" disablePadding >
                        <ListItemButton onClick={handleCurrentHRAction} selected={selectedIndex === 10}
                            disabled={Array.isArray(menuACL) && CURRENT_INDEX < menuACL.length && menuACL[CURRENT_INDEX].disabled} >
                            <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                <Groups2Icon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText primary="Human Resources" primaryTypographyProps={{
                                fontSize: 17,
                                fontWeight: theme.typography.fontWeightMedium,
                                letterSpacing: 0,
                            }} />
                        </ListItemButton>
                    </ListItem>
                    {Array.isArray(extraAreas) && extraAreas.map( (area, index) => {
                        // onClick={handleCurrentExtraAction(area.id,index+100)}
                        return (
                        <ListItem key={`cur-dlib-${area.id}`} disablePadding >
                            <ListItemButton  selected={selectedIndex === (index+100)} onClick={() => handleCurrentExtraAction(area.id,index+100)} 
                                disabled={Array.isArray(menuACL) && CURRENT_INDEX < menuACL.length && menuACL[CURRENT_INDEX].disabled} >
                                { typeof AREA_ICONS[area.id] !== 'undefined' &&
                                <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                    {AREA_ICONS[area.id]}
                                </ListItemIcon> }
                                <ListItemText primary={area.label} primaryTypographyProps={{
                                    fontSize: 17,
                                    fontWeight: theme.typography.fontWeightMedium,
                                    letterSpacing: 0,
                                }} />
                            </ListItemButton>
                        </ListItem>)
                        }
                        )
                    }
                </List>
                <ListItem key="arch-dlib" disablePadding sx={{display: Array.isArray(menuACL) && ARCHIVE_INDEX < menuACL.length && menuACL[ARCHIVE_INDEX].hide ? "none" : "block"}}>
                    <ListItemButton >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <WarehouseIcon />
                        </ListItemIcon>
                        <ListItemText primary="Archive Collection" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <List sx={{ paddingLeft: '40px', paddingTop: 0, display: Array.isArray(menuACL) && ARCHIVE_INDEX < menuACL.length && menuACL[ARCHIVE_INDEX].hide ? "none" : "block" }} >
                    <ListItem key="arc-dlib-deck" disablePadding >
                        <ListItemButton onClick={handleArchiveFlightDeckAction} selected={selectedIndex === 1}
                            disabled={Array.isArray(menuACL) && ARCHIVE_INDEX < menuACL.length && menuACL[ARCHIVE_INDEX].disabled}>
                            <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                <FlightIcon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText primary="Flight Deck" primaryTypographyProps={{
                                fontSize: 17,
                                fontWeight: theme.typography.fontWeightMedium,
                                letterSpacing: 0,
                            }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="arc-dlib-hr" disablePadding >
                        <ListItemButton onClick={handleArchiveHRAction} selected={selectedIndex === 11}
                            disabled={Array.isArray(menuACL) && ARCHIVE_INDEX < menuACL.length && menuACL[ARCHIVE_INDEX].disabled} >
                            <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                <Groups2Icon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText primary="Human Resources" primaryTypographyProps={{
                                fontSize: 17,
                                fontWeight: theme.typography.fontWeightMedium,
                                letterSpacing: 0,
                            }} />
                        </ListItemButton>
                    </ListItem>
                    {Array.isArray(extraAreas) && extraAreas.map( (area, index) => {
                        return (
                        <ListItem key={`arc-dlib-${area.id}`} disablePadding >
                            <ListItemButton  selected={selectedIndex === (index+200)} onClick={() => handleArchiveExtraAction(area.id,index+200)} 
                                disabled={Array.isArray(menuACL) && ARCHIVE_INDEX < menuACL.length && menuACL[ARCHIVE_INDEX].disabled} >
                                { typeof AREA_ICONS[area.id] !== 'undefined' &&
                                <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                    {AREA_ICONS[area.id]}
                                </ListItemIcon> }
                                <ListItemText primary={area.label} primaryTypographyProps={{
                                    fontSize: 17,
                                    fontWeight: theme.typography.fontWeightMedium,
                                    letterSpacing: 0,
                                }} />
                            </ListItemButton>
                        </ListItem>)
                        }
                        )
                    }
                </List>
                <ListItem key="pending-dlib" disablePadding sx={{display: Array.isArray(menuACL) && INPROGRESS_INDEX < menuACL.length && menuACL[INPROGRESS_INDEX].hide ? "none" : "block" }} >
                    <ListItemButton >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <PendingIcon />
                        </ListItemIcon>
                        <ListItemText primary="In Progress" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <List sx={{ paddingLeft: '40px', paddingTop: 0, display: Array.isArray(menuACL) && INPROGRESS_INDEX < menuACL.length && menuACL[INPROGRESS_INDEX].hide ? "none" : "block" }}>
                    <ListItem key="pending-dlib-deck" disablePadding >
                        <ListItemButton onClick={handlePendingFlightDeckAction} selected={selectedIndex === 2}
                            disabled={Array.isArray(menuACL) && INPROGRESS_INDEX < menuACL.length && menuACL[INPROGRESS_INDEX].disabled} >
                            <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                <FlightIcon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText primary="Flight Deck" primaryTypographyProps={{
                                fontSize: 17,
                                fontWeight: theme.typography.fontWeightMedium,
                                letterSpacing: 0,
                            }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="pending-dlib-hr" disablePadding >
                        <ListItemButton onClick={handlePendingHRAction} selected={selectedIndex === 12}
                            disabled={Array.isArray(menuACL) && INPROGRESS_INDEX < menuACL.length && menuACL[INPROGRESS_INDEX].disabled} >
                            <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                <Groups2Icon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText primary="Human Resources" primaryTypographyProps={{
                                fontSize: 17,
                                fontWeight: theme.typography.fontWeightMedium,
                                letterSpacing: 0,
                            }} />
                        </ListItemButton>
                    </ListItem>
                    {Array.isArray(extraAreas) && extraAreas.map( (area, index) => {
                        return (
                        <ListItem key={`pending-dlib-${area.id}`} disablePadding >
                            <ListItemButton  selected={selectedIndex === (index+300)} onClick={() => handlePendingExtraAction(area.id,index+300)} 
                                disabled={Array.isArray(menuACL) && INPROGRESS_INDEX < menuACL.length && menuACL[INPROGRESS_INDEX].disabled} >
                                { typeof AREA_ICONS[area.id] !== 'undefined' &&
                                <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                    {AREA_ICONS[area.id]}
                                </ListItemIcon> }
                                <ListItemText primary={area.label} primaryTypographyProps={{
                                    fontSize: 17,
                                    fontWeight: theme.typography.fontWeightMedium,
                                    letterSpacing: 0,
                                }} />
                            </ListItemButton>
                        </ListItem>)
                        }
                        )
                    }
                </List>
                <ListItem key="upload-dlib" disablePadding sx={{display: Array.isArray(menuACL) && UPLOADS_INDEX < menuACL.length && menuACL[UPLOADS_INDEX].hide ? "none" : "block" }}>
                    <ListItemButton >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <CloudUploadIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Uploads" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <List sx={{ paddingLeft: '40px', paddingTop: 0, display: Array.isArray(menuACL) && UPLOADS_INDEX < menuACL.length && menuACL[UPLOADS_INDEX].hide ? "none" : "block" }}>
                    <ListItem key="upload-dlib-deck" disablePadding >
                        <ListItemButton onClick={handleUploadShelfAction} selected={selectedIndex === 3}
                            disabled={Array.isArray(menuACL) && UPLOADS_INDEX < menuACL.length && menuACL[UPLOADS_INDEX].disabled} >
                            <ListItemIcon sx={{ paddingRight: 0, minWidth: '30px', fontSize: 19 }}>
                                <FolderOpenIcon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText primary="Shelves" primaryTypographyProps={{
                                fontSize: 17,
                                fontWeight: theme.typography.fontWeightMedium,
                                letterSpacing: 0,
                            }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </List>

        </Box>
    );
}



const DlibModule = {
    // label
    name: 'dlib',
    label: 'Digital Library',
    barLabel: 'Digital Library',
    barShortLabel: 'LIB',
    // icon
    icon: <LibraryBooksIcon fontSize="inherit" />,
    // menu
    drawerContent: <DlibMenu reload={wreload}/>,
    // initial body page
    pageContent: null,
    // send new body page on menu actions
    pageChange: (page) => {},
    pageIndexChange: (index) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},

    hooks: {}, // { workflow: { top: workflowInfo } },
    route: 
        {
            path: 'dlib',
            element: <DlibMenu reload={wreload}/>,
        }
    ,
    aclPaths: [ {path: '/C/dlib', label: 'Digital Library'}, 
        {path: '/C/dlib/current', label: 'Current Collection'}, 
        {path: '/C/dlib/archive', label: 'Archive Collection'},
        {path: '/C/dlib/inprogress', label: 'In Progress'},
        {path: '/C/dlib/uploads', label: 'Uploads'},


    ],
}


export default DlibModule;